var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.formattedEmployeeContracts,"fixed-header":"","footer-props":{
            'items-per-page-options': [].concat( _vm.perPageOptions ),
          },"options":{
            'itemsPerPage': _vm.perPage,
            'serverItemsLength': _vm.total,
            'itemsPerPageOptions': _vm.perPageOptions,
            page: _vm.page
          },"server-items-length":_vm.total,"height":'calc(-150px + 100vh)'},on:{"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{staticStyle:{"text-align":"center"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('td',[_vm._v(_vm._s(item.serial))]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.employee_label))]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.contract_label))]),_c('td',[_vm._v(_vm._s(item.start_date_label))]),_c('td',[_vm._v(_vm._s(item.end_date_label))]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(item.age_label))]),_c('td',[_vm._v(_vm._s(item.employee.badge))]),_c('td',[_c('v-btn',{attrs:{"icon":""}},[(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)],1)])]}}])}),_c('employee-contract-details',{attrs:{"employeeContract":_vm.selectedEmployeeContract},on:{"updated":_vm.updatedHandler},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}}),_c('employee-contract-form',{attrs:{"employee-contract":_vm.selectedEmployeeContract,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"created":_vm.createdHandler,"updated":_vm.createdHandler,"deleted":_vm.deletedHandler},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}}),_c('span',{staticClass:"d-none",attrs:{"id":"add-new"},on:{"click":function($event){return _vm.showFormDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }