import {mapState} from "vuex";
import {API_BASE_URL} from "@/config";
import axios from "@/plugins/axios";
import { DateTime } from 'luxon';
import { reject, update } from "lodash";
import moment from "moment";

export default {
    data() {
        return {
            articlePayments: {},
        }
    },
    computed: {
        ...mapState(['selectedExpenseData']),
        totalPaid(){
            let total = 0;
            console.log("article_payments_total_paid", this.articlePayments);
            if(this.articlePayments && this.articlePayments.length > 0){
                this.articlePayments.forEach(item => {
                    if(item && typeof item == 'object' && item.amount){
                        total += parseFloat(item.amount || 0);
                    }
                    else if(item && typeof item == 'number'){
                        total += parseFloat(item.amount || 0);
                    }
                });
            }

            return total.toFixed(2);
        },
        paymentBalance(){
            let total = this.articleTotal - this.totalPaid;
            return (total > 0 ? total : 0).toFixed(2);
        },
        selectedExpenseType(){
            if(!this.selectedExpenseData){
                return null;
            }

            return this.selectedExpenseData?.type?.key ?? null;
        }
    },
    watch: {
        paymentBalance:{
            handler: function(val) {
                console.log("payment balance - all", val, this.articlePayments);
                if(this.articlePayments && this.articlePayments.length > 0){
                    // Mark only Paid/Partial Paid when atleast one item is added in articlePayments
                    if(parseFloat(this.paymentBalance) == 0){
                        this.setExpenseStatus('payed');
                    }
                    else if(parseFloat(this.selectedExpenseData.total) > parseFloat(this.paymentBalance)){
                        this.setExpenseStatus('partial_payed');
                    }
                    else{
                        this.setExpenseStatus('waiting');
                    }
                }
                else if(this.articlePayments && this.articlePayments.length == 0 && this.isPartialOrPaid()){
                    this.setExpenseStatus('waiting');
                }
            }
        },
        selectedExpenseData(val){
            console.log("selected expense data", val);
            this.articlePayments = [];

            let payments = val?.payments || null;
            if(
                (!payments || !Array.isArray(payments )) ||
                (Array.isArray(payments) && payments.length === 0)
            ){
                return;
            }
            console.log("selected expense data payments", payments);

            payments.forEach(payment => {
                this.articlePayments.push(payment);
                // this.articlePaymentsTime[payment.payed_by_id] = payment.updated_at ?? moment().format('YYYY-MM-DD');
            });
        }
    },
    methods: {
        async setExpenseStatus(status_key){
            let allOrderStatuses = [];
            await this.getCodes('order_status', 'billings.statuses')
            .then((data) => {
                allOrderStatuses = data;
            });
            console.log("all order status", allOrderStatuses);
            if(allOrderStatuses && allOrderStatuses.length > 0){
                allOrderStatuses.forEach((element) => {
                    if(element.key == status_key){
                        this.order_status = element;
                    }
                });
            }
        },
        async isPartialOrPaid(){
            let isPartialOrPaidStatus = false;
            let allOrderStatuses = [];
            await this.getCodes('order_status', 'billings.statuses')
            .then((data) => {
                allOrderStatuses = data;
            });
            if(allOrderStatuses && allOrderStatuses.length > 0){
                allOrderStatuses.forEach((element) => {
                    if(element.key == 'partial_payed' || element.key == 'payed'){
                        isPartialOrPaidStatus = true;
                    }
                });
            }
            return isPartialOrPaidStatus;
        },
        addRemainingExpenseToField(item){
            let balance = (parseFloat(this.articleTotal).toFixed(2) - this.totalPaid)
            console.log("expense_balance", balance);
            
            this.saveExpensePayment(item, balance.toFixed(2));
        },
        saveExpensePayment(item, payed_by_id, amount, executed_at){
            return new Promise((resolve, reject) => {
                console.log("payment balance ------=======", this.paymentBalance - amount);
                console.log(amount)
                const payload = {
                    expense_id : this.selectedExpenseData.id,
                    amount : amount ?? 0,
                    payed_by_id: payed_by_id ? ( typeof payed_by_id == 'object' ? payed_by_id.id : payed_by_id) :  null,
                    currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : this.defaultCurrencyId,
                    is_total_paid: this.paymentBalance - amount <= 0 ? 1 : 0
                }
                if(executed_at){
                    console.log("date time updated at", executed_at, DateTime.fromISO(executed_at).toFormat("yyyy-MM-dd HH:mm"));
                    payload['executed_at'] = DateTime.fromISO(executed_at).toFormat("yyyy-MM-dd HH:mm")
                }
                console.log(payload)
    
                const existingPayment = item && typeof item == 'object' && item.id && typeof item.id == 'number' ? item : null;
    
                console.log(existingPayment, "existing_payment")
                const requestMethod = existingPayment ? 'put' : 'post';
    
                const url = requestMethod === 'post' ? API_BASE_URL + '/expense_payments' : API_BASE_URL + '/expense_payments/' + existingPayment.id;
    
                const headers = {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.AccessToken,
                    }
                }
    
                
                axios[requestMethod](url, payload, headers)
                    .then(({data}) => {
                        return resolve(data);
                    })
                    .finally(() => {})
            })
        }
    }
}